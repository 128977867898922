import { Observable } from 'rxjs';
import { MenuService } from '../services/menu.service';
import { Menu } from '../models/menu.model';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable()
export class ProductsResolver implements Resolve<Menu>{
    constructor(private menuService: MenuService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        const type = route.params.type;
        return  this.menuService.getAllMenu(type).pipe(map((menu: Menu[]) => menu));
    }
}
