import { Injectable } from '@angular/core';
import { Product } from './product.model';

export class Menu {
    constructor(
        public id: number,
        public codmen: number,
        public desc: string,
        public estesa: string,
        public products?: Product[]
      ) {}
}

@Injectable({
    providedIn: 'root'
  })
  export class MenuAdapter {
    adapt(item: any): Menu {
        return new Menu(item.id, item.codmen, item.desc, item.estesa);
    }
}
