import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Menu, MenuAdapter } from '../models/menu.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(private http: HttpClient, private adapter: MenuAdapter) { }

  getAllMenu(type: string): Observable<Menu[]>{
    return this.http
    .get(`${environment.API_URL}/menus/`, {params: { type }})
    .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
  }
}
